<template>
  <div class="profile">
    <Header />
    <HeaderBottomProfile />
    <b-container class="profile-container">
      <div
        v-if="(user.individual ? user.individual : user.institutional).investors_risk_profile == null && (user.individual ? user.individual : user.institutional).is_fbonds !== 1">
        <CompleteTheProfile />
      </div>
      <b-row class="justify-content-md-center">
        <div class="col-lg-8 col-md-6">
          <div>
            <b-card class="product-performance mb-5">
              <div class="d-none d-lg-block">
                <b-row>
                  <div class="profile-tab">
                    <b-row class="justify-content-center">
                      <div class="myprofile">
                        <b-col>
                          <h3><b-link to="/profile">Profil</b-link></h3>
                        </b-col>
                      </div>
                      <div class="profile-identity">
                        <b-col>
                          <h3>
                            <b-link to="/profile/identity">Data Diri</b-link>
                          </h3>
                        </b-col>
                      </div>
                      <div class="payment-account">
                        <b-col>
                          <h3><b-link href="#">Rekening Bank</b-link></h3>
                        </b-col>
                      </div>
                      <div class="setting">
                        <b-col>
                          <h3>
                            <b-link to="/setting">
                              <img src="@/assets/img/icons/setting/setting-icon.svg" alt="Setting Icon"
                                class="w-20" /></b-link>
                          </h3>
                        </b-col>
                      </div>
                    </b-row>
                  </div>
                </b-row>
              </div>
              <div class="d-block d-lg-none">
                <b-row>
                  <div class="col-lg-12 col-md-6">
                    <div class="profile-tab">
                      <b-row class="justify-content-center mb-2">
                        <div class="col-lg-12 col-md-6">
                          <b-row class="justify-content-center">
                            <div class="myprofile">
                              <b-col>
                                <h3><b-link to="/profile">Profil</b-link></h3>
                              </b-col>
                            </div>
                          </b-row>
                        </div>
                      </b-row>
                      <b-row class="justify-content-center mb-2">
                        <div class="col-lg-12 col-md-6">
                          <b-row class="justify-content-center">
                            <div class="profile-identity">
                              <b-col>
                                <b-link to="/profile/identity">Data Diri</b-link>
                              </b-col>
                            </div>
                          </b-row>
                        </div>
                      </b-row>
                      <b-row class="justify-content-center mb-2">
                        <div class="col-lg-12 col-md-6">
                          <b-row class="justify-content-center">
                            <div class="payment-account">
                              <b-col>
                                <b-link href="#">Rekening Bank</b-link>
                              </b-col>
                            </div>
                          </b-row>
                        </div>
                      </b-row>
                      <b-row class="justify-content-center">
                        <div class="col-lg-12 col-md-6">
                          <b-row class="justify-content-center">
                            <div class="setting">
                              <b-col>
                                <b-link to="/setting">
                                  <img src="@/assets/img/icons/setting/setting-icon.svg" alt="Setting Icon"
                                    class="w-20" /></b-link>
                              </b-col>
                            </div>
                          </b-row>
                        </div>
                      </b-row>
                    </div>
                  </div>
                </b-row>
              </div>
              <hr />

              <!-- Private -->
              <div class="row py-4">
                <div class="col-12">
                  <p class="account-note">
                    Rekening bank digunakan saat mentransfer ke rekening pribadi
                  </p>
                </div>
              </div>


              <div v-if="user.individual != null">
                <div v-if="user.individual.first_name">
                  <div class="profile-data" v-for="value in privateAccount.defaultData?.private" :value="value.id"
                    :key="value.id">
                    <b-row class="my-2">
                      <div class="col-6 col-lg-3 col-md-6 my-auto">
                        <div class="
                            bank-image
                            text-left text-lg-center
                            mx-4
                            mb-4
                            my-auto
                          ">
                          <div v-if="value.banks.logo == null">
                            <img :src="'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                              value.banks.bank_name
                              " />
                          </div>
                          <div v-else>
                            <img :src="`${imageCore}/${value.banks.logo}`" max-height="26" max-width="86"
                              alt="Bank Image" />
                          </div>

                          <p class="mt-2">
                            {{ value.banks.bank_name }}
                          </p>
                        </div>
                      </div>
                      <div class="col-6 col-lg-6 col-md-6 my-auto">
                        <div class="payment-account-profile mt-1 my-auto">
                          <h3>
                            {{ value.account_name }}
                          </h3>
                          <p>
                            {{ value.account_number }}
                          </p>
                        </div>
                      </div>
                      <div class="col-12 my-auto col-lg-2 col-md-6 text-center">
                        <div class="text-right my-auto text-center">
                          <template class="my-auto" v-if="value.default == 1">
                            <span class="my-auto">Default</span>
                          </template>
                          <template class="my-auto" v-else>
                            <button class="btn-edit-data" :disabled="loading" type="submit" @click="
                              showIdBank(value.id);
                            setDefaultBank();
                            ">
                              Set Default
                            </button>
                          </template>
                        </div>
                      </div>
                      <div class="
                          col-12
                          my-auto
                          col-lg-1 col-md-6
                          text-center
                          py-2
                        ">
                        <div class="text-right my-auto text-center">
                          <template class="my-auto" v-if="value.default == 1">
                          </template>
                          <template class="my-auto" v-else>
                            <button class="btn-edit-data" :disabled="loading" type="submit"
                              @click="$bvModal.show(value.id)">
                              <b-icon class="mr-3 text-muted" icon="trash"></b-icon>
                            </button>
                          </template>
                        </div>
                      </div>
                    </b-row>


                  </div>
                  <div class="profile-data" v-for="value in privateAccount.accountListData?.private" :value="value.id"
                    :key="value.id">

                    <b-row class="my-2">
                      <div class="col-6 col-lg-3 col-md-6 my-auto">
                        <div class="
                            bank-image
                            text-left text-lg-center
                            mx-4
                            mb-4
                            my-auto
                          ">
                          <div v-if="value.banks.logo == null">
                            <img :src="'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                              value.banks.bank_name
                              " />
                          </div>
                          <div v-else>
                            <img :src="`${imageCore}/${value.banks.logo}`" max-height="26" max-width="86"
                              alt="Bank Image" />
                          </div>

                          <p class="mt-2">
                            {{ value.banks.bank_name }}
                          </p>
                        </div>
                      </div>
                      <div class="col-6 col-lg-6 col-md-6 my-auto">
                        <div class="payment-account-profile mt-1 my-auto">
                          <h3>
                            {{ value.account_name }}
                          </h3>
                          <p>
                            {{ value.account_number }}
                          </p>
                        </div>
                      </div>
                      <div class="col-12 my-auto col-lg-2 col-md-6 text-center">
                        <div class="text-right my-auto text-center">
                          <template class="my-auto" v-if="value.default == 1">
                            <span class="my-auto">Default</span>
                          </template>
                          <template class="my-auto" v-else>
                            <button class="btn-edit-data" :disabled="loading" type="submit" @click="
                              showIdBank(value.id);
                            setDefaultBank();
                            ">
                              Set Default
                            </button>
                          </template>
                        </div>
                      </div>
                      <div class="
                          col-12
                          my-auto
                          col-lg-1 col-md-6
                          text-center
                          py-2
                        ">
                        <div class="text-right my-auto text-center">
                          <template class="my-auto" v-if="value.default == 1">
                          </template>
                          <template class="my-auto" v-else>
                            <button class="btn-edit-data" :disabled="loading" type="submit"
                              @click="$bvModal.show(value.id)">
                              <b-icon class="mr-3 text-muted" icon="trash"></b-icon>
                            </button>
                          </template>
                        </div>
                      </div>
                    </b-row>
                    <div class="delete-bank">
                      <b-modal :id="value.id" size="md" class="modal-delete-bank p-4" centered hide-footer hide-header>
                        <div class="d-block text-center modal-text-header px-4">
                          <div class="row justify-content-start text-left">
                            <div class="col-lg-12 col-md-6">
                              <p class="disclaimer-text">
                                <b>Hapus Rekening Bank</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <b-row class="justify-content-center pb-4 px-3">
                          <div class="col-lg-6 col-md-6">
                            <b-button block class="mt-3 mx-2 btn-outline-primary py-2"
                              @click="$bvModal.hide(value.id)">Nanti
                              Saja</b-button>
                          </div>
                          <div class="col-lg-6 col-md-6">
                            <b-button block type="submit" @click="
                              showIdBank(value.id);
                            setInactiveBank();
                            " class="mt-3 mx-2 btn-secondary py-2">Hapus</b-button>
                          </div>
                        </b-row>
                      </b-modal>
                    </div>
                    <hr />

                  </div>
                </div>
                <div v-else>
                  <div class="profile-data mt-4">
                    <b-row class="my-2">
                      <div class="col-lg-9 col-md-6">
                        <div class="payment-account-profile mt-1">
                          <h3>Belum ada data rekening bank</h3>
                        </div>
                      </div>
                      <b-col class="my-auto text-right">
                        <!-- <b-link><p class="btn-edit"><b-icon class="mr-2" icon="pencil"></b-icon>Edit</p></b-link> -->
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-lg-12 text-left py-4">
                    <template class="my-auto text-left" v-if="user.individual.ifua != null">
                      <button class="btn-edit-data" v-b-modal.modal-add-bank>
                        <p
                          class="btn-edit my-auto"
                          style="font-weight: 700; color: #E60012"
                        >
                          <b-icon class="mr-2" icon="plus"></b-icon>Tambah
                          Rekening
                        </p>
                      </button>
                    </template>
                  </div>
                </div> -->
              </div>
              <div v-else></div>

              <!-- Public -->
              <div class="row py-4">
                <div class="col-12">
                  <p class="account-note">
                    Rekening Dana Nasabah
                  </p>
                </div>
              </div>
              <!-- bank digunakan saat menjual kembali Bonds. -->
              <!-- Uang hasil penjualan akan ditransfer oleh Bank Kustodian. -->


              <div v-if="user.individual != null">
                <div v-if="user.individual.first_name">
                  <div class="profile-data" v-for="value in privateAccount.defaultData?.public" :value="value.id"
                    :key="value.id">
                    <b-row class="my-2">
                      <div class="col-6 col-lg-3 col-md-6 my-auto">
                        <div class="
                            bank-image
                            text-left text-lg-center
                            mx-4
                            mb-4
                            my-auto
                          ">
                          <div v-if="value.banks.logo == null">
                            <img :src="'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                              value.banks.bank_name
                              " />
                          </div>
                          <div v-else>
                            <img :src="`${imageCore}/${value.banks.logo}`" max-height="26" max-width="86"
                              alt="Bank Image" />
                          </div>

                          <p class="mt-2">
                            {{ value.banks.bank_name }}
                          </p>
                        </div>
                      </div>
                      <div class="col-6 col-lg-6 col-md-6 my-auto">
                        <div class="payment-account-profile mt-1 my-auto">
                          <h3>
                            {{ value.account_name }}
                          </h3>
                          <p>
                            {{ value.account_number }}
                          </p>
                        </div>
                      </div>
                      <div class="col-12 my-auto col-lg-2 col-md-6 text-center">
                        <div class="text-right my-auto text-center">
                          <template class="my-auto" v-if="value.default == 1">
                            <span class="my-auto">Default</span>
                          </template>
                          <template class="my-auto" v-else>
                            <button class="btn-edit-data" :disabled="loading" type="submit" @click="
                              showIdBank(value.id);
                            setDefaultBank();
                            ">
                              Set Default
                            </button>
                          </template>
                        </div>
                      </div>
                      <div class="
                          col-12
                          my-auto
                          col-lg-1 col-md-6
                          text-center
                          py-2
                        ">
                        <div class="text-right my-auto text-center">
                          <template class="my-auto" v-if="value.default == 1">
                          </template>
                          <template class="my-auto" v-else>
                            <button class="btn-edit-data" :disabled="loading" type="submit"
                              @click="$bvModal.show(value.id)">
                              <b-icon class="mr-3 text-muted" icon="trash"></b-icon>
                            </button>
                          </template>
                        </div>
                      </div>
                    </b-row>


                  </div>
                  <div class="profile-data" v-for="value in privateAccount.accountListData?.public" :value="value.id"
                    :key="value.id">

                    <b-row class="my-2">
                      <div class="col-6 col-lg-3 col-md-6 my-auto">
                        <div class="
                            bank-image
                            text-left text-lg-center
                            mx-4
                            mb-4
                            my-auto
                          ">
                          <div v-if="value.banks.logo == null">
                            <img :src="'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                              value.banks.bank_name
                              " />
                          </div>
                          <div v-else>
                            <img :src="`${imageCore}/${value.banks.logo}`" max-height="26" max-width="86"
                              alt="Bank Image" />
                          </div>

                          <p class="mt-2">
                            {{ value.banks.bank_name }}
                          </p>
                        </div>
                      </div>
                      <div class="col-6 col-lg-6 col-md-6 my-auto">
                        <div class="payment-account-profile mt-1 my-auto">
                          <h3>
                            {{ value.account_name }}
                          </h3>
                          <p>
                            {{ value.account_number }}
                          </p>
                        </div>
                      </div>
                      <div class="col-12 my-auto col-lg-2 col-md-6 text-center">
                        <div class="text-right my-auto text-center">
                          <template class="my-auto" v-if="value.default == 1">
                            <span class="my-auto">Default</span>
                          </template>
                          <template class="my-auto" v-else>
                            <button class="btn-edit-data" :disabled="loading" type="submit" @click="
                              showIdBank(value.id);
                            setDefaultBank();
                            ">
                              Set Default
                            </button>
                          </template>
                        </div>
                      </div>
                      <div class="
                          col-12
                          my-auto
                          col-lg-1 col-md-6
                          text-center
                          py-2
                        ">
                        <div class="text-right my-auto text-center">
                          <template class="my-auto" v-if="value.default == 1">
                          </template>
                          <template class="my-auto" v-else>
                            <button class="btn-edit-data" :disabled="loading" type="submit"
                              @click="$bvModal.show(value.id)">
                              <b-icon class="mr-3 text-muted" icon="trash"></b-icon>
                            </button>
                          </template>
                        </div>
                      </div>
                    </b-row>
                    <div class="delete-bank">
                      <b-modal :id="value.id" size="md" class="modal-delete-bank p-4" centered hide-footer hide-header>
                        <div class="d-block text-center modal-text-header px-4">
                          <div class="row justify-content-start text-left">
                            <div class="col-lg-12 col-md-6">
                              <p class="disclaimer-text">
                                <b>Hapus Rekening Bank</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <b-row class="justify-content-center pb-4 px-3">
                          <div class="col-lg-6 col-md-6">
                            <b-button block class="mt-3 mx-2 btn-outline-primary py-2"
                              @click="$bvModal.hide(value.id)">Nanti
                              Saja</b-button>
                          </div>
                          <div class="col-lg-6 col-md-6">
                            <b-button block type="submit" @click="
                              showIdBank(value.id);
                            setInactiveBank();
                            " class="mt-3 mx-2 btn-secondary py-2">Hapus</b-button>
                          </div>
                        </b-row>
                      </b-modal>
                    </div>
                    <hr />

                  </div>
                </div>
                <div v-else>
                  <div class="profile-data mt-4">
                    <b-row class="my-2">
                      <div class="col-lg-9 col-md-6">
                        <div class="payment-account-profile mt-1">
                          <h3>Belum ada data rekening bank</h3>
                        </div>
                      </div>
                      <b-col class="my-auto text-right">
                        <!-- <b-link><p class="btn-edit"><b-icon class="mr-2" icon="pencil"></b-icon>Edit</p></b-link> -->
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-lg-12 text-left py-4">
                    <template class="my-auto text-left" v-if="user.individual.ifua != null">
                      <button class="btn-edit-data" v-b-modal.modal-add-bank>
                        <p
                          class="btn-edit my-auto"
                          style="font-weight: 700; color: #E60012"
                        >
                          <b-icon class="mr-2" icon="plus"></b-icon>Tambah
                          Rekening
                        </p>
                      </button>
                    </template>
                  </div>
                </div> -->
              </div>
              <div v-else></div>
            </b-card>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div>
            <PortfolioSidebar />
            <TransactionHistorySidebar />
          </div>
        </div>
      </b-row>

      <b-modal id="modal-add-bank" size="md" class="modal-add-bank p-4" centered hide-footer hide-header>
        <div class="d-block text-center modal-text-header px-4">
          <div class="row justify-content-start text-left">
            <div class="col-lg-12 col-md-6">
              <p class="disclaimer-text pt-4">
                <b>Ketentuan penambahan rekening baru</b>
              </p>
              <p class="disclaimer-text">
              <ul class="ml-3">
                <li>Pemilik rekening harus sama dengan yang didaftarkan di CGS iTrade Fund.</li>
                <li>Penulisan nama pemilik rekening sama dengan yang terdaftar di bank.</li>
                <li>Rekening baru akan diverifikasi dan bisa digunakan setelah 1-2 hari.</li>
                <li>Apabila ada kesalahan input data, rekening harus dihapus dan dibuat ulang</li>
              </ul>
              </p>
            </div>
          </div>
        </div>
        <b-row class="justify-content-center pb-4 px-3">
          <div class="col-lg-6 col-md-6">
            <b-button block class="mt-3 mx-2 btn-outline-primary py-2" @click="$bvModal.hide('modal-add-bank')">Nanti
              Saja</b-button>
          </div>
          <div class="col-lg-6 col-md-6">
            <b-button block type="submit" to="/profile/add-account"
              class="mt-3 mx-2 btn-secondary py-2">Lanjut</b-button>
          </div>
        </b-row>
      </b-modal>

      <div class="modal-change-bank">
        <b-modal id="modal-success-change-bank" v-model="successChangeBank" size="md" class="modal-success-change-bank"
          centered hide-footer hide-header no-close-on-backdrop>
          <div class="d-block text-center">
            <img class="pt-4" src="@/assets/img/icons/transactions/checked-blue.svg" alt="Success Change Bank" />
            <h1 class="p-4 my-2 text-dark">Rekening Utama Berhasil Diubah</h1>
            <b-row class="justify-content-md-end btn-submit-row mb-4">
              <div class="col-lg-12 col-md-6">
                <b-button size="sm" block class="btn-tertiary px-4 py-2" href="/profile/payment-account">Lihat Daftar
                  Rekening</b-button>
              </div>
            </b-row>
          </div>
        </b-modal>
      </div>

      <div class="modal-change-bank">
        <b-modal id="modal-success-change-bank" v-model="failedChangeBank" size="md" class="modal-success-change-bank"
          centered hide-footer no-close-on-backdrop>
          <div class="d-block text-center">
            <img src="@/assets/img/ekyc/failed.svg" class="pt-4" alt="Failed Change Bank" />
            <h1 class="p-4 my-2 text-dark">
              Ups! <br />
              Terjadi kesahan.
            </h1>
            <b-row class="justify-content-center">
              <b-button href="https://api.whatsapp.com/send?phone=6287777936468" block
                class="mt-3 mx-2 btn-tertiary py-2">Hubungi Customer Care</b-button>
            </b-row>
          </div>
        </b-modal>
      </div>

      <div class="modal-inactive-bank">
        <b-modal id="modal-success-inactive-bank" v-model="successInactiveBank" size="md"
          class="modal-success-inactive-bank" centered hide-footer hide-header no-close-on-backdrop>
          <div class="d-block text-center">
            <img class="pt-4" src="@/assets/img/icons/transactions/checked-blue.svg" alt="Success Inactive Bank" />
            <h1 class="p-4 my-2 text-dark">Rekening Telah Dihapus</h1>
            <b-row class="justify-content-md-end btn-submit-row mb-4">
              <div class="col-lg-12 col-md-6">
                <b-button size="sm" block class="btn-tertiary px-4 py-2" href="/profile/payment-account">Lihat Daftar
                  Rekening</b-button>
              </div>
            </b-row>
          </div>
        </b-modal>
      </div>

      <div class="modal-inactive-bank">
        <b-modal id="modal-success-inactive-bank" v-model="failedInactiveBank" size="md"
          class="modal-success-inactive-bank" centered hide-footer no-close-on-backdrop>
          <div class="d-block text-center">
            <img class="pt-4" src="@/assets/img/ekyc/failed.svg" alt="Failed Inactive Bank" />
            <h1 class="p-4 my-2 text-dark">
              Ups! <br />
              Terjadi kesahan.
            </h1>
            <b-row class="justify-content-center">
              <b-button href="https://api.whatsapp.com/send?phone=6287777936468" block
                class="mt-3 mx-2 btn-tertiary py-2">Hubungi Customer Care</b-button>
            </b-row>
          </div>
        </b-modal>
      </div>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/partials/header/Header.vue";
import HeaderBottomProfile from "@/components/partials/header/HeaderBottomProfile.vue";
import CompleteTheProfile from "@/components/partials/profile/CompleteTheProfile.vue";
import PortfolioSidebar from "@/components/partials/profile/PortfolioSidebar.vue";
import TransactionHistorySidebar from "@/components/partials/profile/TransactionHistorySidebar.vue";
import Footer from "@/components/partials/footer/Footer.vue";
import { encryptData } from '@/utils'

import instance from "@/utils/api";

export default {
  name: "ProfilePayment",
  metaInfo: {
    title: "Profil - Rekening Bank | CGS iTrade Fund",
  },
  components: {
    Header,
    HeaderBottomProfile,
    CompleteTheProfile,
    PortfolioSidebar,
    TransactionHistorySidebar,
    Footer,
  },
  data() {
    return {
      ekyc: {},
      imageCore: process.env.VUE_APP_IMAGE_URL,
      successChangeBank: false,
      failedChangeBank: false,
      successInactiveBank: false,
      failedInactiveBank: false,
      loading: false,
      selectedIdBank: "",
      processing: false,
      // Additional
      s21ClientData: null,
    };
  },
  computed: {
    ...mapGetters({
      bearer_token: "bearer_token",
      check: "auth/check",
      user: "auth/user",
    }),
    defaultAccountList: function () {
      return (this.user.individual ? this.user.individual : this.user.institutional).accounts.filter(function (u) {
        return u.default === 1;
      });
    },
    accountList: function () {
      return (this.user.individual ? this.user.individual : this.user.institutional).accounts.filter(function (u) {
        return u.status != 0 && u.default != 1;
      });
    },
    privateAccount: function () {
      const source = [...this.defaultAccountList];
      const source2 = [...this.accountList];
      // selectKindOfBank

      const defaultData = this.selectKindOfBank(source);
      const accountListData = this.selectKindOfBank(source2);

      return {
        defaultData,
        accountListData
      };
    }
  },
  methods: {
    showIdBank: function (id) {
      this.selectedIdBank = id;
    },
    setDefaultBank() {
      if (!this.processing) {
        this.processing = true;
        let formData = new FormData();
        formData.append("bank_account_id", this.selectedIdBank);

        let axiosConfig = {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        };

        instance
          .post(
            "individual-account/change-default-bank-account",
            formData,
            axiosConfig
          )
          .then((res) => {
            console.log("AXIOS SUCCESS: ", res);
            this.loading = !false;
            this.successChangeBank = true;

            this.processing = false;
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
            this.failedInactiveBank = true;
            this.processing = false;

            // this.errorMessage = err.response.data.data.message;
          });
      }
    },
    setInactiveBank() {
      if (!this.processing) {
        this.processing = true;
        let formData = new FormData();
        formData.append("bank_account_id", this.selectedIdBank);

        let axiosConfig = {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        };

        instance
          .post(
            "individual-account/inactive-bank-account",
            formData,
            axiosConfig
          )
          .then((res) => {
            console.log("AXIOS SUCCESS: ", res);
            this.loading = !false;
            this.successInactiveBank = true;

            this.processing = false;
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
            this.failedChangeBank = true;
            this.processing = false;

            // this.errorMessage = err.response.data.data.message;
          });
      }
    },
    /**
     * @param {Array} source 
     */
    selectKindOfBank(source) {
      return source.reduce((prev, current) => {
        const newPrev = { ...prev };
        // // Opsi 1
        // // Ambil Bedasarkan bank id
        // if (current?.banks?.bank_name == this?.s21ClientData?.PrivateBankID) {
        //   newPrev['private'] = [...newPrev['private'], current];
        // }

        // if (current?.banks?.bank_name == this?.s21ClientData?.BankID) {
        //   newPrev['public'] = [...newPrev['public'], current];
        // }

        // Opsi 2
        // Ambil Bedasarkan Nomor
        if (current?.account_number == this?.s21ClientData?.PrivateBankAccountNo) {
          newPrev['private'] = [...newPrev['private'], current];
        }

        if (current?.account_number == this?.s21ClientData?.BankAccountNo) {
          newPrev['public'] = [...newPrev['public'], current];
        }

        return newPrev;
      }, {
        private: [],
        public: [],
      })
    }
  },
  mounted() {
    const clientId = (this.user?.individual ? this.user?.individual : this.user?.institutional)?.client_id;

    if (!clientId) {
      alert("Error In App");
      console.error("Client ID Not Found");
      return;
    }
    instance
      .get(`s21client?clientId=${encryptData(clientId)}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).then(res => {
        const respond = res?.data?.data;
        if (!respond) {
          throw new Error("Struktur Respond Data Tidak Sesuai");
        }

        this.s21ClientData = respond
      }).catch(err => {
        alert("Error In App");
        console.error(`Ada error : ${err}`);
      })
  }
};
</script>

<style lang="scss" scoped>
.profile {
  background: #f3f4f6;
}

.profile-container {
  padding-top: 140px;
  padding-bottom: 120px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;

  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  color: #4b5563;
}

a {
  text-decoration: none;
}

hr {
  margin: 0 !important;
}

.account-note {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #374151;
}

.myprofile {
  margin-left: 10px;

  a {
    padding-left: 40px;
    padding-right: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    text-align: center;
    color: #111827;
    text-decoration: none;
  }
}

.profile-identity {
  a {
    padding-left: 40px;
    padding-right: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    text-align: center;
    color: #111827;
    text-decoration: none;
  }
}

.payment-account {
  border-bottom: 3px solid $secondaryColor;

  a {
    padding-left: 40px;
    padding-right: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    text-align: center;
    color: #111827;
    text-decoration: none;
  }
}

.setting {
  a {
    padding-left: 40px;
    padding-right: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    text-align: center;
    color: #111827;
    text-decoration: none;
  }
}

.profile-data {
  padding: 10px 20px 10px 20px;
  border-radius: 6px;

  .profile-image {
    img {
      border-radius: 6px;
      width: 40px;
    }
  }

  .profile-name {
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #111827;
    }
  }

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }

  .value {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #111827;
  }

  .payment-account-profile {
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #4b5563;
      margin-bottom: 0px;
    }

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;

      color: #111827;
    }
  }

  a {
    text-decoration: none;
  }

  .bank-image {
    img {
      border-radius: 6px;
      width: 60px;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #4b5563;
      margin-bottom: 0px;
    }
  }

  .btn-edit {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }
}

@media only screen and (max-width: 768px) {
  p {
    font-size: 14px !important;
  }

  .myprofile {
    margin-left: 0px;

    a {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .disclaimer-text {
    font-size: 16px !important;
  }

  .profile-identity {
    a {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .payment-account {
    a {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .setting {
    a {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
</style>
